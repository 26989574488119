<template>
  <div class="body crt">
    <div class="square">
      <div class="pixel-text">
        <!-- put invisible whitespace in each sub pixel text to keep min height before the texts are inserted -->
        <!-- might not be the best way to do it but I ain't a front end dev -->
        <div id="pixel-text-1">
          ‎<!--          GREETINGS PROFESSOR FALKEN-->
        </div>
        <br>
        <div id="pixel-text-2">
          ‎<!--          HELLO-->
        </div>
        <br>
        <div id="pixel-text-3">
          ‎<!--          A STRANGE GAME.-->
        </div>
        <div id="pixel-text-4">
          ‎<!--          THE ONLY WINNING MOVE IS-->
        </div>
        <div id="pixel-text-5">
          ‎<!--          NOT TO PLAY.-->
        </div>
        <br>
        <div id="pixel-text-6">
          ‎<!--          HOW ABOUT A NICE GAME OF DISAPPEARING?-->
        </div>
      </div>
      <SocialConnections/>
    </div>
  </div>
</template>

<script>
import SocialConnections from "@/components/SocialConnections";
const idAndTextPairs = {
  'pixel-text-1': 'GREETINGS PROFESSOR FALKEN',
  'pixel-text-2': 'HELLO',
  'pixel-text-3': 'A STRANGE GAME.',
  'pixel-text-4': 'THE ONLY WINNING MOVE IS',
  'pixel-text-5': 'NOT TO PLAY.',
  // 'pixel-text-6': 'HOW ABOUT A NICE GAME OF DISAPPEARING?',
};

const timeBetweenCharacters = 100;  // in milliseconds
const timeBetweenIds = 500;  // in milliseconds

export default {
  name: 'StrangeGame',
  components: {SocialConnections},
  async mounted() {
    // iterate over each id and text pair
    for (const [id, text] of Object.entries(idAndTextPairs)) {
      // find the element by id
      const element = document.getElementById(id);

      // iterate over each character in the text
      for (let i = 0; i < text.length; i++) {
        // insert the character at that index
        element.innerHTML += text.charAt(i);

        // sleep for set amount of time between each character
        await new Promise(r => setTimeout(r, timeBetweenCharacters));
      }

      // sleep for set amount of time between each line/id
      await new Promise(r => setTimeout(r, timeBetweenIds));
    }
  }
}
</script>

<style>
@import '../styles/crt.css';

html, body {
  background-color: black;
  height: 98%;
}

.body, .square {
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  min-height: 98vh;
  background-color: black
}

.square {
  width: 140vmin;
  height: 97vmin;

  border: 6px solid rgba(220, 220, 255, 1);
  box-shadow: inset 50px 50px 1000px 5px rgba(44, 44, 180, 0.2),
  inset -50px 50px 1000px 5px rgba(44, 44, 180, 0.2),
  1px 1px 1000px 1px rgba(44, 44, 180, 0.2),
  -1px 1px 1000px 1px rgba(44, 44, 180, 0.2);
}

.pixel-text {
  color: rgba(220, 220, 255, 1);
  font-size: calc(2.2vw + 2.2vh);
  font-family: pixelTextFont, sans-serif;
  width: 100%;
  text-align: left;
  margin-left: 3.5vw;
  /*margin-bottom: 10vh;*/
  /*margin-top: 10vh;*/
  /*align-content: baseline !important;;*/
  /*display: flex !important;*/
}

.glow {
  text-shadow: 0 0 100px rgba(88, 88, 180, 1)
  /*,0 0 300px rgba(44, 44, 180, 1)*/
  /*0 0 500px rgba(44, 44, 180, 1)*/
  /*,0 0 200px rgba(44, 44, 180, 0.5)*/
  /*,0 0 300px rgba(44, 44, 180, 0.5)*/
  /*,0 0 400px rgba(44, 44, 180, 0.5)*/
  /*,0 0 500px rgba(44, 44, 180, 0.5)*/
  /*,0 0 60px rgba(44, 44, 180, 1)*/
  /*,0 0 700px rgba(44, 44, 180, 1)*/;
}

@font-face {
  font-family: pixelTextFont;
  src: url(../assets/UniformRndCond-Light.otf);
  font-weight: bold;
}
</style>
